<template>
  <div>
    <div class="vx-row">
  <feather-icon v-if="EnglishLan" @click="back()" icon="ChevronLeftIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <feather-icon v-else @click="back()" icon="ChevronRightIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <p class="text-xl">
        {{ $t("PatientMedicalHistory") }}
      </p>
    </div>
    <img style="width: 100%" alt="" src="@/assets/images/patientInfo.png" />
    <div >
      <medicalHistory :patientModel="patientModel" />
    </div>
    <div class="w-full mt-5">
      <div class="justify-right" style="text-align: end">
        <vs-button @click="Submit()" type="filled">{{ $t("Save") }}</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
// import point from "../../components/point.vue";
import modulePatient from "@/store/Patient/modulePatient.js";
import medicalHistory from "@/views/patientProfile/medicalHistory.vue";
import moduleInsuranceCompany from "@/store/settings/insuranceCompany/moduleInsuranceCompany.js";

export default {
  components: {
    // point
    medicalHistory,
  },
  data() {
    return {
      EnglishLan:false,
      patientModel: {
        PatientMedicalHistory: {
          PatientMedicalHistoryMedications:[],
          PatientMedicalHistoryPastSurgeries:[]
        },
      },
      options: [],
      radios1: "luis",
    };
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    Submit() {
      this.$vs.loading();
      this.$store
        .dispatch("patientList/UpdatePatient", this.patientModel)
        .then((res) => {
          window.showSuccess(res.data.message);
          console.error(res);
          this.$vs.loading.close();
          this.$router.push({ name: "LandingPage" });

          // this.initPatientModelValues();
        })
        .catch((err) => {
          window.showError(err.response.data.message);
          this.$vs.loading.close();
          console.error(err);
        });
    },
  },
  computed: {},
  created() {
    debugger

      this.EnglishLan = localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null? true : false;

    debugger;
  if (!moduleInsuranceCompany.isRegistered) {
      this.$store.registerModule("InsuranceCompanyList", moduleInsuranceCompany);
      moduleInsuranceCompany.isRegistered = true;
    }

    this.$store.dispatch("InsuranceCompanyList/GetAllInsuranceCompanies");
    if (!modulePatient.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      debugger;
      this.$vs.loading();
      this.$store.dispatch("patientList/GetPatientForMainData", ID).then((res) => {
        this.patientModel = res.data.Data;
        if (
          this.patientModel.PatientMedicalHistory == null ||
          this.patientModel.PatientMedicalHistory == undefined
        ) {
          this.patientModel.PatientMedicalHistory = {};
        }
        else{
          debugger
        if(this.patientModel.PatientMedicalHistory.IsLowPressure==true){
            this.patientModel.PatientMedicalHistory.IsHavePresssure = undefined;
          }
          if(this.patientModel.NumOfChildren>0){
               this.patientModel.HaveChildren =  true;
             }
        }
        this.$vs.loading.close();

      });
    }
  },
};
</script>
<style>

.textfont {
  font-family: "futuraMedium";
}
</style>
